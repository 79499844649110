import React from "react";

function PlaceContacts({ contacts, onChange }) {
  return (
    <>
      <div className="font-semibold text-sm text-center">CONTACTS</div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Phone</label>
        <input
          type="text"
          name="phone"
          onChange={onChange}
          value={contacts.phone}
          className="custom-input"
          placeholder="Phone"
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Website</label>
        <input
          type="text"
          name="website"
          onChange={onChange}
          value={contacts.website}
          className="custom-input"
          placeholder="Website"
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Instagram</label>
        <input
          type="text"
          name="instagram"
          onChange={onChange}
          value={contacts.instagram}
          className="custom-input"
          placeholder="Instagram"
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Facebook</label>
        <input
          type="text"
          name="facebook"
          onChange={onChange}
          value={contacts.facebook}
          className="custom-input"
          placeholder="Facebook"
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Telegram</label>
        <input
          type="text"
          name="telegram"
          onChange={onChange}
          value={contacts.telegram}
          className="custom-input"
          placeholder="Telegram"
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">LinkedIn</label>
        <input
          type="text"
          name="linkedin"
          onChange={onChange}
          value={contacts.linkedin}
          className="custom-input"
          placeholder="LinkedIn"
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Tiktok</label>
        <input
          type="text"
          name="tiktok"
          onChange={onChange}
          value={contacts.tiktok}
          className="custom-input"
          placeholder="Tiktok"
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">WhatsApp</label>
        <input
          type="text"
          name="whatsapp"
          onChange={onChange}
          value={contacts.whatsapp}
          className="custom-input"
          placeholder="WhatsApp"
        />
      </div>
    </>
  );
}

export default PlaceContacts;
