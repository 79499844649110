import React from "react";
import { MdAddAPhoto } from "react-icons/md";

const AnnouncementEditSection = ({ announcement, setAnnouncement }) => {
  const handleAnnouncementFieldChange = (event) => {
    const { name, value } = event.target;
    setAnnouncement((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  return !announcement ? (
    <span>Loading...</span>
  ) : (
    <div className="flex flex-col justify-center items-center">
      <div className="w-2/3 h-52 border rounded-lg">
        {announcement.image ? (
          <img
            src={announcement.image}
            alt="imageUrl"
            className="w-full h-full object-cover rounded-lg"
          />
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-md bg-gray-50">
            <div className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50">
              <MdAddAPhoto size="32" className="text-gray-600" />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full gap-4 mt-4">
        <label className="relative text-[10px] text-gray-700">
          Image
          <input
            name="image"
            onChange={handleAnnouncementFieldChange}
            defaultValue={announcement.image}
            placeholder="Announcement image"
            className="custom-input"
            required
          />
        </label>
        <label className="relative text-[10px] text-gray-700">
          Title
          <input
            name="title"
            onChange={handleAnnouncementFieldChange}
            defaultValue={announcement.title}
            placeholder="Announcement title"
            className="custom-input"
            maxLength={48}
            required
          />
        </label>
        <label className="relative text-[10px] text-gray-700">
          Text
          <textarea
            name="text"
            onChange={handleAnnouncementFieldChange}
            value={announcement.text}
            rows="6"
            className="custom-input"
            placeholder="Announcement content"
            required
          />
        </label>
        <label className="relative text-[10px] text-gray-700">
          Link
          <input
            name="link"
            onChange={handleAnnouncementFieldChange}
            defaultValue={announcement.link}
            placeholder="Announcement link"
            className="custom-input"
          />
        </label>
        <div className="flex gap-2 items-center">
          <input
            onChange={() => {
              setAnnouncement((prevState) => ({
                ...prevState,
                isVisible: !announcement.isVisible,
              }));
            }}
            checked={announcement.isVisible}
            name="isVisible"
            type="checkbox"
            className="custom-checkbox"
          />
          <span className="text-sm">Visible</span>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementEditSection;
