import React from "react";
import { Link } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { BsSaveFill } from "react-icons/bs";

const AnnouncementActionHeader = ({ loading, isNew }) => {
  return (
    <div className="flex mb-6 justify-between items-center">
      <div className="flex items-center">
        <Link
          to={"/announcements"}
          className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
        >
          <IoChevronBackSharp />
          BACK
        </Link>
        <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
          <span>CREATE PROMO</span>
        </div>
      </div>
      <div className="flex items-center">
        <button
          form="announcement-create-form"
          type="submit"
          disabled={loading}
          className={`edit-btn`}
        >
          <BsSaveFill />
          {isNew ? "CREATE" : "SAVE"}
        </button>
      </div>
    </div>
  );
};

export default AnnouncementActionHeader;
