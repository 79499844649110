import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import { updateAnnouncement } from "./announcementApi";
import { toast } from "react-toastify";
import AnnouncementEditSection from "../../components/Announcement/AnnouncementEditSection";
import AnnouncementActionHeader from "../../components/Announcement/AnnouncementActionHeader";

const EditAnnouncement = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <div className="max-w-xl mx-auto">
      <AnnouncementActionHeader loading={isSubmitting} isNew={false} />
      <AnnouncementEditForm setIsSubmitting={setIsSubmitting} />
    </div>
  );
};

const AnnouncementEditForm = ({ setIsSubmitting }) => {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const navigate = useNavigate();
  const location = useLocation();
  const announcement = location.state ? location.state.announcement : null;
  const [announcementRequest, setAnnouncementRequest] = useState();

  useEffect(() => {
    if (announcement) {
      setAnnouncementRequest({
        id: announcement.id,
        title: announcement.title,
        text: announcement.text,
        link: announcement.link,
        image: announcement.image,
        isVisible: announcement.isVisible,
      });
    }
  }, [announcement]);

  const handleAnnouncementCreate = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    updateAnnouncement(announcementRequest, authHeaderValue)
      .then(() => {
        toast.success("Announcement has been updated");
        navigate(`/announcements/${announcement.id}`);
      })
      .catch((error) => toast.error(error))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="bg-white p-8 rounded-md shadow-md">
      <form id="announcement-create-form" onSubmit={handleAnnouncementCreate}>
        <AnnouncementEditSection
          announcement={announcementRequest}
          setAnnouncement={setAnnouncementRequest}
        />
      </form>
    </div>
  );
};

export default EditAnnouncement;
