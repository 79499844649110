import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import useFetch from "../../hooks/useFetch";
import { setInvisible, setVisible } from "./announcementApi";
import { toast } from "react-toastify";
import { IoChevronBackSharp } from "react-icons/io5";
import { AiOutlineEye, AiTwotoneEdit } from "react-icons/ai";

const AnnouncementDetails = () => {
  const params = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const { data: announcement, loading } = useFetch(
    `/v1/admin/announcements/${params.announcementId}`,
  );

  return loading ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-xl mx-auto">
      <AnnouncementsActionHeader
        announcement={announcement}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <AnnouncementsInformation
        announcement={announcement}
        isVisible={isVisible}
      />
    </div>
  );
};

const AnnouncementsActionHeader = ({
  announcement,
  isVisible,
  setIsVisible,
}) => {
  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (announcement) {
      setIsVisible(announcement.isVisible);
    }
  }, [setIsVisible, announcement]);

  const handleVisibilityChange = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      isVisible
        ? await setInvisible(announcement.id, authHeaderValue)
        : await setVisible(announcement.id, authHeaderValue);
      setIsVisible((prev) => !prev);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex mb-6 justify-between items-center">
      <div className="flex items-center">
        <Link
          to={"/announcements"}
          className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
        >
          <IoChevronBackSharp />
          BACK
        </Link>
        <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
          <span>ANNOUNCEMENT:</span>
          <span>{announcement.id}</span>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <button
          onClick={handleVisibilityChange}
          disabled={isSubmitting}
          className={"block-btn"}
        >
          <AiOutlineEye />
          {isVisible ? "HIDDEN" : "VISIBLE"}
        </button>
        <button
          onClick={() =>
            navigate(`/announcements/${announcement.id}/edit`, {
              state: { announcement: announcement },
            })
          }
          className={`edit-btn`}
        >
          <AiTwotoneEdit />
          EDIT
        </button>
      </div>
    </div>
  );
};

const AnnouncementsInformation = ({ announcement, isVisible }) => (
  <div className="rounded-md shadow-md bg-white p-8">
    <div className="flex justify-between">
      <div className="block text-xs text-gray-600">
        {new Date(announcement.createdAt).toLocaleDateString()}
      </div>
      <div className="flex items-center gap-2 text-sm">
        <div
          className={`p-1 rounded-full ${isVisible ? "bg-green-500" : "bg-yellow-500"}`}
        />
        {isVisible ? "Visible" : "Hidden"}
      </div>
    </div>
    <hr className="mt-4" />
    <div className="flex w-full justify-center mt-4">
      <div className="w-2/3 h-52">
        <div
          style={{ backgroundImage: `url(${announcement.image})` }}
          className="w-full h-full border rounded-lg relative rounded-lg bg-center bg-cover"
        />
      </div>
    </div>
    <div className="grid grid-cols-5 gap-4 mt-5">
      <span className="col-span-1 text-gray-700 font-semibold text-sm">
        Title:
      </span>
      <span className="col-span-4 text-sm break-words">
        {announcement.title}
      </span>
      <span className="col-span-1 text-gray-700 font-semibold text-sm">
        Message:
      </span>
      <span className="col-span-4 text-sm break-words">
        {announcement.text}
      </span>
      <span className="col-span-1 text-gray-700 font-semibold text-sm">
        Link:
      </span>
      <span className="col-span-4 text-sm break-words">
        {announcement.link}
      </span>
    </div>
  </div>
);

export default AnnouncementDetails;
