import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { AiOutlinePlus } from "react-icons/ai";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import PaginationControl from "../../components/Table/PaginationControl";
import { IoIosArrowDown } from "react-icons/io";
import Avatar from "../../components/Images/Avatar";

const Announcements = () => {
  const [searchSetting, setSearchSetting] = useState({
    page: 0,
    size: 10,
    status: "",
  });
  const { data, loading } = useFetch(
    `/v1/admin/announcements?page=${searchSetting.page}&pageSize=${searchSetting.size}&status=${searchSetting.status}`,
  );

  const handleSearchSettingsChange = (event) => {
    const { name, value } = event.target;
    setSearchSetting((prevEntity) => ({
      ...prevEntity,
      [name]: value,
      page: 0,
    }));
  };

  return (
    <div>
      <AnnouncementsHeader />
      {loading ? (
        <span>Loading...</span>
      ) : (
        <div className="bg-white p-6 rounded-md shadow-md">
          <AnnouncementSearchActions onSelect={handleSearchSettingsChange} />
          <AnnouncementsTable data={data} />
          <PaginationControl
            searchSetting={searchSetting}
            setSearchSetting={setSearchSetting}
            paginationSettings={data.pageMetadata}
          />
        </div>
      )}
    </div>
  );
};

const AnnouncementsHeader = () => (
  <div className="flex justify-between mb-6 items-center">
    <span className="font-bold text-lg">ANNOUNCEMENTS</span>
    <Link to={"/announcements/create"} className={`submit-btn`}>
      <AiOutlinePlus />
      CREATE
    </Link>
  </div>
);

const AnnouncementSearchActions = ({ onSelect }) => (
  <div className="grid justify-start grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 gap-x-8 gap-2 mb-6">
    <div className="relative">
      <label className="relative text-[10px] text-gray-700">
        Status
        <select
          name="status"
          defaultValue="visible"
          onChange={onSelect}
          className="custom-select"
        >
          <option value="">All</option>
          <option value="visible">Visible</option>
          <option value="hidden">Hidden</option>
        </select>
        <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
      </label>
    </div>
  </div>
);

const AnnouncementsTable = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="overflow-x-auto table-auto">
      <table className="w-full">
        <thead className="text-gray-700 text-xs bg-gray-100 ">
          <tr>
            <TableHeader name="ID" />
            <TableHeader name="TITLE" />
            <TableHeader name="LINK" />
            <TableHeader name="VISIBILITY" />
            <TableHeader name="CREATED AT" />
          </tr>
        </thead>
        <tbody>
          {data.data.map((announcement, i) => (
            <tr
              key={i}
              onClick={() => navigate(`/announcements/${announcement.id}`)}
              className="border-b text-xs hover:bg-gray-50 duration-300 hover:cursor-pointer"
            >
              <TableRow value={announcement.id} />
              <TableRow
                value={
                  <div className="flex items-center gap-2">
                    <Avatar image={announcement.image} />
                    <div>{announcement.title}</div>
                  </div>
                }
              />
              <TableRow value={announcement.link} />
              <TableRow
                value={
                  <div className="flex items-center gap-2">
                    <div
                      className={`p-1 rounded-full ${announcement.isVisible ? "bg-green-500" : "bg-orange-500"}`}
                    />
                    {announcement.isVisible ? "Visible" : "Hidden"}
                  </div>
                }
              />
              <TableRow
                value={new Date(announcement.createdAt).toLocaleDateString()}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Announcements;
