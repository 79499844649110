import React, { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { createAnnouncement } from "./announcementApi";
import { toast } from "react-toastify";
import AnnouncementEditSection from "../../components/Announcement/AnnouncementEditSection";
import AnnouncementActionHeader from "../../components/Announcement/AnnouncementActionHeader";

const CreateAnnouncement = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <div className="max-w-xl mx-auto">
      <AnnouncementActionHeader loading={isSubmitting} isNew={true} />
      <AnnouncementCreateForm setIsSubmitting={setIsSubmitting} />
    </div>
  );
};

const AnnouncementCreateForm = ({ setIsSubmitting }) => {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const navigate = useNavigate();
  const [announcement, setAnnouncement] = useState({
    title: "",
    text: "",
    link: "",
    image: "",
    isVisible: true,
  });

  const handleAnnouncementCreate = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    createAnnouncement(announcement, authHeaderValue)
      .then((id) => {
        toast.success("Announcement has been created");
        navigate(`/announcements/${id}`);
      })
      .catch((error) => toast.error(error))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="bg-white p-8 rounded-md shadow-md">
      <form id="announcement-create-form" onSubmit={handleAnnouncementCreate}>
        <AnnouncementEditSection
          announcement={announcement}
          setAnnouncement={setAnnouncement}
        />
      </form>
    </div>
  );
};

export default CreateAnnouncement;
