export const createAnnouncement = async (announcement, authHeaderValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/announcements`,
      {
        method: "POST",
        body: JSON.stringify(announcement),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error(`Request failed with status ${response.status}`);
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't create announcement");
  }
};

export const updateAnnouncement = async (announcement, authHeaderValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/announcements/${announcement.id}`,
      {
        method: "PUT",
        body: JSON.stringify(announcement),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error(`Request failed with status ${response.status}`);
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't update announcement");
  }
};

export const setVisible = async (announcementId, authHeaderValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/announcements/${announcementId}/visible`,
      {
        method: "POST",
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't set announcement visible");
  }
};

export const setInvisible = async (announcementId, authHeaderValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/announcements/${announcementId}/invisible`,
      {
        method: "POST",
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't set announcement hidden");
  }
};
