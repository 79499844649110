import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import Avatar from "../../components/Images/Avatar";
import Select from "react-select";
import EditSlider from "../../components/Slider/EditSlider";
import PlaceContacts from "../../components/Place/PlaceContacts";
import PlaceGeneralInfo from "../../components/Place/PlaceGeneralInfo";
import SearchTags, {
  createOption,
} from "../../components/SearchTags/SearchTags";
import { toast } from "react-toastify";
import { useAuthHeader } from "react-auth-kit";
import { BsSaveFill } from "react-icons/bs";
import {
  addSearchTagsToPlace,
  validatePlaceBeforeCreate,
} from "../../utils/PlaceUtils";
import LocationUpdate from "../../components/Map/LocationUpdate";

function EditPlace() {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const location = useLocation();
  const navigate = useNavigate();
  const place = location.state ? location.state.place : null;
  const [placeRequest, setPlaceRequest] = useState();
  const [placeSearchTags, setPlaceSearchTags] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: tags, loading: tagsLoading } = useFetch(`/v1/tags`);
  const { data: searchTags, loading: searchTagsLoading } = useFetch(
    `/v1/admin/places/${place.id}/searchTags`,
  );

  useEffect(() => {
    if (place) {
      setPlaceRequest({
        id: place.id,
        name: place.name,
        city: place.city,
        countryCode: place.countryCode
          ? createOption(place.country, place.countryCode)
          : null,
        price: place.price,
        address: place.address,
        lat: place.lat,
        lon: place.lon,
        typeId: place.placeType?.id,
        categoryId: place.placeCategory.id,
        tagIds: place.placeTags.map((tag) => createOption(tag.name, tag.id)),
        photos: place.photos,
        contacts: place.contacts,
      });
    }
  }, [place]);

  useEffect(() => {
    if (searchTags && !searchTagsLoading) {
      setPlaceSearchTags(
        searchTags.map((tag) => createOption(tag.name, tag.id)),
      );
    }
  }, [searchTags, searchTagsLoading]);

  const handeGeneralContactsChange = (event) => {
    const { name, value } = event.target;
    setPlaceRequest((prevEntity) => ({
      ...prevEntity,
      contacts: { ...prevEntity.contacts, [name]: value },
    }));
  };

  const createPlace = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/places/${place.id}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          ...placeRequest,
          tagIds: placeRequest.tagIds.map((tag) => tag.value),
          countryCode: placeRequest.countryCode.value,
        }),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      toast.error("Couldn't edit place: " + response.status);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      console.log(placeRequest);
      validatePlaceBeforeCreate(placeRequest);
    } catch (error) {
      toast.warning(error.message);
      setIsSubmitting(false);
      return;
    }
    try {
      console.log(placeRequest);
      const savedPlace = await createPlace();
      console.log(savedPlace);
      await addSearchTagsToPlace(
        savedPlace.id,
        placeSearchTags,
        authHeaderValue,
      );
      toast.success("Place has been updated");
      navigate(`/places/${savedPlace.id}`);
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  return tagsLoading || !place ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-2xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={`/places/${place.id}`}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>PLACE:</span>
            <span>{place.id}</span>
          </div>
        </div>
        {!place.isBlocked && (
          <div className="flex items-center gap-4">
            <button
              className={`submit-btn`}
              type="submit"
              disabled={isSubmitting}
              form="update-place-form"
            >
              <BsSaveFill />
              SAVE
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <div className="rounded-md shadow-md bg-white p-8">
          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <Avatar image={place.createdBy.photoUrl} />
              <div className="hidden text-left lg:block">
                <div className="block text-sm font-medium text-black">
                  {place.createdBy.firstName} {place.createdBy.lastName}
                </div>
                <div className="block text-xs text-gray-600">
                  {new Date(place.createdAt).toLocaleDateString()}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 text-sm">
              <div
                className={`p-1 rounded-full ${place.isBlocked ? "bg-yellow-500" : "bg-lime-500"}`}
              />
              {place.isBlocked ? "Blocked" : "Active"}
            </div>
          </div>
          <hr className="mt-4 mb-8" />
          <div className="flex justify-center">
            <div className="w-full sm:w-2/3 md:w-[60%] h-56">
              <EditSlider entity={placeRequest} setEntity={setPlaceRequest} />
            </div>
          </div>
          <form onSubmit={handleSubmit} id="update-place-form">
            <div className="flex flex-col gap-3 mt-8">
              <div className="relative">
                <label className="block text-[10px] text-gray-700">
                  Search tags
                </label>
                <SearchTags
                  value={placeSearchTags}
                  setValue={setPlaceSearchTags}
                />
              </div>
              <div className="relative">
                <label className="block text-[10px] text-gray-700">
                  Place tags
                </label>
                <Select
                  required
                  value={placeRequest.tagIds}
                  onChange={(value) =>
                    setPlaceRequest((prevEntity) => ({
                      ...prevEntity,
                      tagIds: value,
                    }))
                  }
                  className="rounded-full text-xs"
                  placeholder="Select place tags"
                  isMulti={true}
                  options={tags.map((type) => ({
                    value: type.id,
                    label: type.name,
                  }))}
                ></Select>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
                <div className="flex flex-col gap-3">
                  <PlaceGeneralInfo
                    place={placeRequest}
                    onChange={setPlaceRequest}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <PlaceContacts
                    contacts={placeRequest.contacts}
                    onChange={handeGeneralContactsChange}
                  />
                </div>
              </div>
              <LocationUpdate
                entity={placeRequest}
                setEntity={setPlaceRequest}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditPlace;
